import settings from "@/settings";
import axios from "axios";

class Ticket {
  // 列出所有工單
  static get() {
    return axios.get(`${settings.api.fullPath}/job_ticket/v1/`);
  }

  // 查詢時間區間工單
  static search(start_date, end_date, params) {
    return axios.get(
      `${settings.api.fullPath}/job_ticket/v1/search_for_date/${start_date}/${end_date}`,
      { params: params }
    );
  }

  // 下載時間區間工單
  static getFile(start_date, end_date) {
    return axios.get(
      `${settings.api.fullPath}/job_ticket/v1/report/${start_date}/${end_date}`
    );
  }

  // 新增工單
  static create(formData) {
    return axios.post(`${settings.api.fullPath}/job_ticket/v1/`, formData);
  }

  // 修改工單
  static patchTicket(sn, params) {
    return axios.patch(
      `${settings.api.fullPath}/job_ticket/v1/save_ticket/${sn}`,
      params
    );
  }

  // 新增草稿
  static draft(content, name) {
    return axios.post(`${settings.api.fullPath}/job_ticket/v1/create_draft`, {
      content,
      name,
      state: "draft",
    });
  }

  // 刪除草稿
  static deleteDraft(sn) {
    return axios.post(
      `${settings.api.fullPath}/job_ticket/v1/delete_draft/${sn}`
    );
  }

  // 修改草稿
  static patchDraft(sn) {
    return axios.patch(
      `${settings.api.fullPath}/job_ticket/v1/save_draft/${sn}`
    );
  }

  // process 工單
  static process(sn, action, params) {
    return axios.patch(
      `${settings.api.fullPath}/job_ticket/v1/process_ticket/${sn}/${action}`,
      params
    );
  }

  // upload files
  static uploadFiles(formData) {
    return axios.post(
      `${settings.api.fullPath}/job_ticket/v1/upload_files/`,
      formData
    );
  }

  // 查詢工單歷史紀錄
  static history(sn) {
    return axios.get(`${settings.api.fullPath}/job_ticket_history/v1/${sn}`);
  }
}

export default Ticket;
